import "../styles/globals.css";

import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";

import { ThemeProvider } from "next-themes";
import { useEffect } from "react";

import * as gtag from "../lib/gtag";
import { GTM_ID, pageview } from "../lib/gtm";

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter();
	useEffect(() => {
		router.events.on("routeChangeComplete", pageview);
		const handleRouteChange = (url: string) => {
			gtag.pageview(url);
		};
		router.events.on("routeChangeComplete", handleRouteChange);
		router.events.on("hashChangeComplete", handleRouteChange);
		return () => {
			router.events.off("routeChangeComplete", pageview);
			router.events.off("routeChangeComplete", handleRouteChange);
			router.events.off("hashChangeComplete", handleRouteChange);
		};
	}, [router.events]);

	return (
		<ThemeProvider defaultTheme="light" enableSystem={false}>
			{/* Google Tag Manager - Global base code */}
			<Script
				id="gtag-base"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
				}}
			/>
			<Component {...pageProps} />
		</ThemeProvider>
	);
}

export default MyApp;
